<template>



    <b-row>


      <b-col
        v-if="code200"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="padding: 0;"
      >



        <b-form
          autocomplete="off"
          @submit.prevent="onSubmit"
        >

        <b-card>
          <h4 class="font-weight-bolder">
              Alias de perfil
            </h4>
            <hr class="mb-2">
       

          <b-row>

            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 15px;"
            >

              <p class="textLabel">
                Mi Alias (*):
              </p>

              <b-input-group>
                <b-input-group-prepend is-text>
                   <feather-icon
                      icon="UserIcon"
                      size="18"
                      style="margin-right: 5px;"
                      /> 
                </b-input-group-prepend>
                <b-form-input
                  v-model="alias2"
                  :readonly="activarCampo"
                  id="alias"
                  maxlength="20"
                  type="text"
                  required
                  @blur="checkAlias"
                  @keyup="keyUpAlias" placeholder="Ingrese su alias" @paste.prevent
                />
              </b-input-group>

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
            >

              <p
                class="textLabel"
                style="text-align: center;"
              >
                Ingrese su código PIN (*):  <feather-icon
             icon="HelpCircleIcon"
             size="16"
              v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
            title="Código PIN de seguridad"
             style="margin-right: 5px;cursor:pointer;"
             /> 
            </p>

              <PincodeInput
                v-model="pin"
                required
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :readonly="activarCampo"
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
              style="margin-top:10px; margin-bottom: 10px;"
            >

              <b-button
                id="btn_registrar"
                ref="btn_registrar"
                :disabled="activarCampo"
                variant="primary"
                type="submit"
              >

                Registrar mi alias
              </b-button>
            </b-col>

          

          </b-row>

        </b-card>

        </b-form>


        <b-row>
          <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="padding: 0;"
            >

            <div style="margin-bottom:15px">

              <b-alert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span> Antes de registrar su alias, por favor tenga en cuenta las siguientes preguntas:</span>
                </div>
              </b-alert>

            </div>

          

              <app-collapse
                accordion
                type="margin"
              >

                <app-collapse-item title="¿Qué es un alias y para que sirve?" icon="InfoIcon">
                  <p>Un "Alias" es un nombre único que te servirá para identificarte como negocio, cuando un cliente te busque dentro del marketplace de compra/venta de criptomonedas o utilize tus links de cobros lo hará a través de tu alias.</p>

                </app-collapse-item>

                <app-collapse-item title="¿Qué debo tener a consideración para registrar un Alias?" icon="InfoIcon">

                  <p>Para registrar un "Alias" debe tener en cuenta las siguientes características:</p>

                  <u style="text-decoration: none;">
                    <li>Debe ser único, no se permite registrar alias repetidos de otros usuarios.</li>
                    <li>No puede contener espacios en blanco.</li>
                    <li>Es de máximo 20 caracteres.</li>
                    <li>Está conformado por letras minúsculas, números y guión bajo, no se permite otros caracteres especiales.</li>

                  </u>

                </app-collapse-item>

                <app-collapse-item title="¿Puedo modificar mi Alias más de una vez?" icon="InfoIcon">
                  No, el registro del "Alias" se lo realiza una única vez, una vez registrado ya no podrá modificarlo.
                </app-collapse-item>

              </app-collapse>

            </b-col>
        </b-row>

      </b-col>

      <b-col
        v-else
        sm="12"
        md="12"
        lg="12"
        xl="12"
        align="center"
        style="padding: 0px;"
      >

      <b-card>

        <p style="text-align: center; font-size: 14px">
          Tu alias registrado es:
        </p>

        <p style="text-align: center; font-weight: bold; font-size: 34px">
          {{ alias2 }}
        </p>

        <p style="text-align: center;font-size: 14px">
          Comparte tu alias con tus amigos
        </p>

        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="3"
            xl="3"
          />

          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
          >

            <b-input-group>

              <b-form-input
                v-model="alias2"
                readonly
              />
              <b-input-group-append>
                <b-button
                @click="onCopy"
                  variant="outline-primary"
                >

                   <feather-icon
             icon="CopyIcon"
             size="15"
            
             /> 
                </b-button>
              </b-input-group-append>
            </b-input-group>

          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="3"
            xl="3"
          />
        </b-row>

      </b-card>

      </b-col>

    </b-row>


</template>

<script>
import {
  BButton, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, BSpinner, BAlert, BRow, VBPopover, BCol, BCard,
} from 'bootstrap-vue'

import PincodeInput from 'vue-pincode-input'
import AppCollapse from '@core/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/app-collapse/AppCollapseItem.vue'
import Vue from 'vue'

export default {
  components: {
    PincodeInput,
    AppCollapse,
    BAlert,
    BFormInput,
    AppCollapseItem,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCard,
    BSpinner,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {


     const datos_json = JSON.parse(this.$userGlobal);

       let alias2= datos_json.alias;


       let code200=false;

    if (alias2 === '') {

      code200 = true
    } else {

      code200 = false
    }



    return {
      pin: '',
      code200: code200,
      activarCampo: false,
      alias2: alias2,

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

  },
  methods: {


    checkAlias() {

let aliasSplit= this.alias2.split("");

        
    const specialChar = "áéíóú~!@#$%^&´*_-+=`|(){}[]:;\"'<>,.?/";
    const hasSpecLet = aliasSplit.some((letter) => 
      specialChar.includes(letter)
    );


    if(hasSpecLet){

      this.alias2="";

      this.$toast.error("Su alias es incorrecto, asegúrese de que no contenga ningún caracter especial como tildes, espacios en blanco etc", {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })

    }



  },

    keyUpAlias() {
      this.alias2 = this.alias2.toLowerCase()

      this.alias2 = this.alias2.replace(/[^_-\w]+$/, '')
    },
    onCopy() {
      navigator.clipboard.writeText(this.alias2);
      
      this.$toast.success('Texto copiado!', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },
    onError() {
      this.$toast.error('Falló en copiar el texto', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },

    refreshAlias() {
      this.$eventBus.$emit('reiniciarAlias')
    },
    onSubmit(event) {
      event.preventDefault()

      const data_json = { userId: this.userId, pin: this.pin, alias: this.alias2 }
      const data_string = JSON.stringify(data_json)

    
      const dataEncripted = this.$encryptBackoffice.encrypt(data_string)

      this.activarCampo = true

      document.getElementById('btn_registrar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando'

      this.$https.post('/profile/registerAlias/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
        document.getElementById('btn_registrar').innerHTML = 'Registrar alias'
        this.activarCampo = false

        if (response.data.code == 200) {

          const datos_json = JSON.parse(this.$userGlobal);

          datos_json.alias = this.alias2


          Vue.prototype.$userGlobal= JSON.stringify(datos_json);

           localStorage.setItem('userData', JSON.stringify(datos_json));

        


     
          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

  
                      
          this.refreshAlias();
          
          this.eqcoins(response.data.eqpoints, "asociar un alias a tu perfil.", "");
          this.$eventBus.$emit('reiniciarNotificacionesNavBar');

        } else {
          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })
    },

  },
}
</script>

<style lang="scss">



</style>
