<template>
 

 <b-card
        v-if="loadingPin"
        align="center"
      >

      <b-row>
        <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        align="center"
      >

       

          <h4 class="font-weight-bolder">
          Configuración del Bot EQCoop
          <hr>
        </h4>
  
 

      

      </b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">

<b-skeleton width="100%"  height="60px"></b-skeleton>

</b-col>

  <b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center">



<b-skeleton width="50%"  height="10px"></b-skeleton>
<b-skeleton width="30%"  height="30px"></b-skeleton>

</b-col>


<b-col cols="6" sm="6" md="6" lg="6" xl="6" align="center">


<b-skeleton width="50%"  height="10px"></b-skeleton>
<b-skeleton width="30%"  height="30px"></b-skeleton>
</b-col>





  </b-row>
 </b-card>

       <b-card v-else-if="code200">

        <b-row>

       <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        align="center"
      >

       

          <h4 class="font-weight-bolder">
          Configuración del Bot EQCoop
          <hr>
        </h4>
  
 

      

      </b-col>

      <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:15px">
             <b-alert
            variant="primary"
            show
            style="margin-top: 10px;"
            >
            <div class="alert-body" style="text-align: center;">
            <p style="text-align: center; font-size:12px; line-height: 25px;">Para comenzar a utilizar el bot de EQCoop, es necesario que cumpla con los siguientes dos requisitos:</p>

            </div>
            </b-alert>


          </b-col>

             <b-col cols="6" sm="6" md="6" lg="6" xl="6" style="    text-align: center;margin-bottom: 15px;">

           




                            <p class="textLabel" style="text-align: center;"> Código Pin de seguridad</p>


                   <feather-icon
                    icon="CheckCircleIcon"
                    size="39"
                    v-if="hasPin"
                    style="color_icon"
                    />

                    <b-button id="1" :disabled="isDisabled" @click="disableButton('1')"  v-else variant="primary"  :to="{ name: 'perfil', hash: '#pin'}" > Registrar </b-button>




             </b-col>

               <b-col cols="6" sm="6" md="6" lg="6" xl="6" style="    text-align: center;margin-bottom: 15px;">

          

          

                           <p class="textLabel" style="text-align: center;">Número de Whatsapp</p>

                            <feather-icon
                    icon="CheckCircleIcon"
                    size="39"
                    v-if="hasWhatsapp"
                    style="color_icon"
                    />

                    <b-button id="2" :disabled="isDisabled" @click="disableButton('2')"  v-else variant="primary"  :to="{ name: 'perfil', hash: '#notificaciones'}" > Registrar </b-button>



             </b-col>




     

        </b-row>

        </b-card>



      

 

       <b-card align="center"  v-else>

        <b-row>

          
        <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        align="center"
      >

       

          <h4 class="font-weight-bolder">
          Configuración del Bot EQCoop
          <hr>
        </h4>
  
 

      

      </b-col>

      <b-col
        sm="12"
        md="12"
        lg="12"
        xl="12"
        align="center"
      >

      
      <img

        alt="Bot EQCoop"
        src="/img/bot.svg"
        style="width: 115px;"
        >

        <b-alert
            variant="primary"
            show
            style="margin-top: 10px;"
            >
            <div class="alert-body" style="text-align: center;">
            <p style="text-align: center; font-size:14px; line-height: 25px;">Su cuenta de usuario ya puede hacer uso del bot de EQCoop.</p>

            </div>
            </b-alert>

            <div align="center" style="margin-top:15px" >
                    
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=19452074178&text=ayuda"> <b-button
                        variant="primary"
                                                  
                                                  style="border-radius:50px"
                                             
                                                >

                                                <svg width="18" height="18" style="margin-right: 15px;"  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#fff" ><path d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>  Utilizar el bot de EQCoop

                                                </b-button></a>

                                              </div>


      </b-col>

        </b-row>



         

         </b-card>


</template>

<script>
import {
  BButton, BForm, BAlert, VBPopover,  BSkeleton, BRow, BCol, BCard
} from 'bootstrap-vue'



export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BAlert,
    BCard,
    BSkeleton,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {



    return {
      hasPin: false,
      hasWhatsapp: false,
      code200: false,
      loadingPin: true,

    }
  },
  computed: {


  },
  watch: {

 
 
  },
  mounted() {


    const dataEncripted = this.$encryptBackoffice.encrypt(this.userId)

    this.$https.post('/profile/checkRequerimentsBots/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      if (response.data.code == 200) {
        this.loadingPin = false

        this.hasPin  = response.data.hasPin;

         this.hasWhatsapp  = response.data.hasWhatsapp;

         if(this.hasPin && this.hasWhatsapp){
          this.code200 = false;
         }else{
          this.code200 = true;
         }


          

       
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
  },
  methods: {


  },
}
</script>

<style lang="scss">




</style>
